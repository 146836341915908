import { ReactNode } from 'react';
import LightHouseTheme from './LightHouseTheme';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import { CssBaseline, ThemeProvider } from '@mui/material';

interface IThemeWrapperProps {
  children: ReactNode;
}

const generateClassName = createGenerateClassName({
  productionPrefix: 'lwmSettings',
  seed: 'lwmSettings',
});

const LightHouseThemeProvider = ({ children }: IThemeWrapperProps) => {
  return (
    <StylesProvider generateClassName={generateClassName} injectFirst>
      <ThemeProvider key="lwmSettings-theme" theme={LightHouseTheme}>
        <CssBaseline>{children}</CssBaseline>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default LightHouseThemeProvider;
