import { createRoot } from 'react-dom/client';
import './i18n';

import './theme/FontFace.css';
import LightHouseThemeProvider from './theme/LightHouseThemeProvider';
import mergedConfig, { MICROFRONTEND_ID } from './config';
import MicroFrontendContext from './components/MicroFrontendContext';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AvvAgreementsPage } from './pages/avvAgreements';
import { MandantenPage } from './pages/mandanten';
import { SettingsPage } from './pages/settings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { PriceListManagement } from './components/price-lists/price-list-management';
import { AlertSnackBar } from './components/alert-snackbar/alert-snackbar';
import { IMicrofrontendProps, Microfrontend } from '@hmg-aka-lu/cockpit-microfrontend';
import { FunctionComponent } from 'react';

declare global {
  interface Window {
    [MICROFRONTEND_ID]: Microfrontend;
  }
}

const LWMMicrofrontend: FunctionComponent<IMicrofrontendProps> = ({ getIdToken, permissions }) => {
  const queryClient = new QueryClient();

  return (
    <LightHouseThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <MicroFrontendContext.Provider value={{ getIdToken, permissions: permissions }}>
          <BrowserRouter>
            <Routes>
              <Route path={`/${MICROFRONTEND_ID}/avv-zustimmungen`} element={<AvvAgreementsPage />} />
              <Route path={`/${MICROFRONTEND_ID}/mandanten`} element={<MandantenPage />} />
              <Route path={`/${MICROFRONTEND_ID}/settings`} element={<SettingsPage />} />
              <Route path={`/${MICROFRONTEND_ID}/price-lists`} element={<PriceListManagement />} />
              <Route path="*" element={<Navigate replace to={`/${MICROFRONTEND_ID}/settings`} />} />
            </Routes>
          </BrowserRouter>
        </MicroFrontendContext.Provider>
      </QueryClientProvider>
      <AlertSnackBar />
    </LightHouseThemeProvider>
  );
};

const microFrontendInstance: Microfrontend = (containerId = 'root', props) => {
  const rootElement = document.getElementById(containerId);
  if (!rootElement) {
    throw new Error('DOM root node not found');
  }

  return {
    render: () => {
      createRoot(rootElement).render(
        <LWMMicrofrontend getIdToken={props.getIdToken} permissions={props.permissions} />
      );
    },
  };
};

window.lwmSettings = microFrontendInstance;

if (mergedConfig.STANDALONE) {
  const microFrontend = microFrontendInstance('root', { permissions: ['Superuser'], getIdToken: async () => '' });
  microFrontend.render();
}
