import { ChangeEvent, FunctionComponent, useState } from 'react';
import { Box, Fab, TextField, makeStyles } from '@material-ui/core';
import { Trans } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { usePriceListProductsQuery } from '../../queries/price-list-queries';

const useStyles = makeStyles((_theme) => ({
  textfield: {
    minWidth: '400px',
  },
}));

interface IAddNewPriceList {
  getIdToken: () => Promise<string>;
  onNewProduct: (product: string) => void;
}
export const AddNewPriceTable: FunctionComponent<IAddNewPriceList> = ({ onNewProduct: onNewPriceList, getIdToken }) => {
  const classes = useStyles();
  const [newProductName, setNewProductName] = useState('');
  const { data: products } = usePriceListProductsQuery(getIdToken);
  const availableProduct = products.findIndex((item) => item.toLowerCase() === newProductName.toLowerCase()) === -1;

  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setNewProductName(event.target.value);
  };

  const handleClickAdd = () => {
    onNewPriceList(newProductName);
    setNewProductName('');
  };

  return (
    <Box>
      <TextField
        onChange={handleInputChange}
        value={newProductName}
        label={<Trans i18nKey={'priceLists.add'} />}
        className={classes.textfield}
      />
      <Fab color={'primary'} onClick={handleClickAdd} size="medium" disabled={!availableProduct}>
        <Add />
      </Fab>
    </Box>
  );
};
