import { Box } from '@mui/material';
import React from 'react';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export const TabPanel = ({ children, index, value, ...other }: ITabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      sx={{ backgroundColor: '#FFF', padding: '12px', width: '100%' }}
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={0}>{children}</Box>}
    </Box>
  );
};
