export const i18n_de = {
  title: 'Lernweltmanager',
  common: {
    cancel: 'Abbrechen',
    delete: 'Löschen',
  },
  pages: {
    avvAgreements: 'AVV-Zustimmungen',
    mandanten: 'Mandanten',
    settings: 'LWM Konfiguration',
  },
  avvTable: {
    title: 'AVV-Zustimmungen',
    new: 'Neuer Eintrag',
    search: 'Nach Mandant suchen...',
    deleteWarning:
      'Sind Sie sicher, dass Sie die AVV-Zustimmung für den Mandant "{{mandantId}}" wirklich löschen möchten?',
    errors: {
      fetch: 'Fehler beim Laden der Daten. Bitte laden sie die Seite erneut.',
    },
    header: {
      mandant: 'Mandant',
      user: 'Benutzer',
      date: 'Erstellungsdatum',
      source: 'Ursprung',
      version: 'LWM-Version',
    },
    add: {
      title: 'AVV-Zustimmung anlegen',
      failed: 'Anlage der neuen AVV-Zustimmung ist fehlgeschlagen.',
      mandantExists: 'Es ist bereits eine Zustimmung für diese MandantId hinterlegt.',
      submit: 'AVV-Zustimmung anlegen',
    },
  },
  mandantenTable: {
    header: {
      id: 'ID',
      name: 'Name',
      description: 'Beschreibung',
      createdAt: 'Erstellt',
      updatedAt: 'Änderungsdatum',
      scormDownload: 'SCORM-Connector Download',
      sso: 'SSO',
      ssoWithUserDataComparison: 'SSO mit Nutzerdatenabgleich',
    },
    edit: {
      scormDownload: 'SCORM-Connectoren im Lernwelt Manager bereitstellen',
      save: 'Speichern',
      cancel: 'Abbrechen',
    },
    updateSuccess: '"{{mandant}}" erfolgreich aktualisiert',
    updateError: 'Fehler beim Aktualisieren von "{{mandant}}"',
  },
  deleteConfirmation: {
    title: 'Löschen bestätigen',
    failed: 'Löschen fehlgeschlagen, bitte versuchen Sie es erneut',
  },
  settings: {
    titleNewsBanner: 'News Banner einrichten',
    form: {
      enabled: 'Banner in LWM anzeigen',
      text: 'Text',
      textPlaceholderDe: 'Bitte trage hier den deutschen Banner-Text ein',
      textPlaceholderEn: 'Bitte trage hier den englischen Banner-Text ein',
      link: 'Link',
      linkPlaceholder: 'Hier kannst du einen weiterführenden Link eintragen.',
      color: 'Banner-Farbe',
      colorText: 'Bitte Farbe auswählen',
      helpText: '*Plichtfeld',
      helpTextLink: 'Der Link öffnet sich mit Klick auf den Banner. Der Link selbst ist nicht sichtbar.',
    },
    selectLanguages: 'Sprachversion der Dateien',
    languages: {
      de: 'Deutsch',
      en: 'English',
    },
    save: 'Speichern',
    successAlert: 'Erfolgreich gespeichert',
    failAlert: 'Speichern fehlgeschlagen',
    confirmationDeleteMsg: 'Eintrag wirklich löschen?',
    cancel: 'Abbrechen',
    delete: 'Löschen',
    deactivated: 'Deaktiviert',
    new: 'Neu',
    tabs: {
      banner: 'News Banner',
      accordion: 'Aktuelle Meldungen',
      assets: 'Dateimanagement',
    },
    accordionForm: {
      activated: 'Aktiviert',
      title: 'Titel',
      content: 'Inhalt',
      openByDefault: 'immer ausgeklappt',
      newLabel: '"neu" - Label',
      cta: 'Aktions - Button',
      ctaLabel: 'Buttontitel',
      ctaUrl: 'Url',
      tooltip: 'Tooltip',
      tooltipText: 'Text',
    },
    assets: {
      de: {
        manual: 'Benutzeranleitung',
        avv: 'AVV',
        agb: 'AGB',
      },
      en: {
        manual: 'Benutzeranleitung',
        avv: 'AVV',
        agb: 'AGB',
      },
      uploadFile: 'Neue Datei hochladen',
      cachingNote:
        'Achtung - Der Browser cached die alte Datei. Somit kann es nach dem Upload eine Weile dauern, bis bei Klick die neue Datei angezeigt wird. Über den Inkognito-Modus lässt sich die Änderung direkt nachvollziehen.',
      onSuccess: 'Datei wurde erfolgreich hochgeladen.',
      onError:
        'Fehler beim Hochladen der Datei. Bitte versuchen Sie es erneut. Gegebenfalls kann ein erneutes Laden der Seite helfen.',
      invalidFileType: 'Ungültiger Dateityp. Bitte wählen Sie eine PDF-Datei.',
    },
  },
  priceLists: {
    header: 'Preislisten verwalten',
    selectLabel: 'Verfügbare Preislisten',
    table: {
      piece: 'Stückzahl',
      price: 'Gesamt-Preis',
    },
    invalidInputValue: 'Bitte geben Sie einen Wert >= 0 ein.',
    note: 'Bitte beachten Sie, dass bei der Pflege nur ganzzahlige Werte unterstützt werden!',
    add: 'Neue Preisliste hinzufügen',
    deleteHeader: 'Preisliste {{product}} löschen?',
    deleteText: 'Sind Sie sicher, dass Sie die Preisliste für das Produkt {{product}} löschen möchten?',
    noPriceListSelected: 'Keine Preisliste ausgewählt.',
    fetchFailed:
      'Das Laden der Preisliste ist fehlgeschlagen. Bitte versuchen Sie es erneut. Gegebenfalls kann ein erneutes Laden der Seite helfen.',
    deleteFailed:
      'Fehler beim Löschen der Preisliste. Bitte versuchen Sie es erneut. Gegebenfalls kann ein erneutes Laden der Seite helfen.',
    deleteEntryFailed:
      'Fehler beim Löschen des Eintrags. Bitte versuchen Sie es erneut. Gegebenfalls kann ein erneutes Laden der Seite helfen.',
    saveFailed:
      'Fehler beim Speichern des Eintrags. Bitte versuchen Sie es erneut. Gegebenfalls kann ein erneutes Laden der Seite helfen.',
  },
};
