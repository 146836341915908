import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApiService } from '../../api/api-request';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid';

const Colors = {
  Grau: '#252525',
  Orange: '#FF9300',
  Blau: '#00ABF7',
  Pink: '#ED1E79',
  Grün: '#03B9B6',
};

interface IBannerProps {
  getIdToken: () => Promise<string>;
}

const Banner: FunctionComponent<IBannerProps> = (props) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [textDE, setTextDE] = useState<string>('');
  const [link, setLink] = useState<string | undefined>();
  const [textEN, setTextEN] = useState<string>('');
  const [isValidLink, setIsValidLink] = useState<boolean>(true);
  const [color, setColor] = React.useState('');

  const handleChangeColor = (event: SelectChangeEvent) => {
    setColor(event.target.value as string);
  };

  const handleChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnabled(event.target.checked);
  };

  const handleCheckLink = (linkValue: string) => {
    if (linkValue === '') {
      return setIsValidLink(true);
    } else {
      try {
        new URL(linkValue);
        return setIsValidLink(true);
      } catch (error) {
        return setIsValidLink(false);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      try {
        const settings = await apiService.getSettings('Banner');
        if (settings) {
          const banner = settings.data;
          setEnabled(banner.enabled);
          setTextDE(banner.translations.de.text);
          setTextEN(banner.translations.en.text);
          setLink(banner.translations.de.link);
          setColor(banner.backgroundColor);
        }
      } catch (error) {
        // setError(`${t('settings.failAlert')}: ${String(error)}`);
      }
      setLoading(false);
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSnackbar = () => {
    setShowAlert(false);
  };

  const handleSubmit = async () => {
    if (isValidLink === true) {
      setLoading(true);
      try {
        await apiService.updateSettings('Banner', {
          enabled,
          backgroundColor: color,
          translations: {
            de: {
              text: textDE,
              link: link,
            },
            en: {
              text: textEN,
            },
          },
        });
        setShowAlert(true);
      } catch (error) {
        setError(`${t('settings.failAlert')}: ${String(error)}`);
      }
    }
    setLoading(false);
  };

  const apiService = getApiService(props.getIdToken);

  return (
    <Grid>
      <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Alert severity="success">{t('settings.successAlert')}</Alert>
      </Snackbar>
      {loading ? (
        <Box p={4} pt={8} pb={8} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <Paper sx={{ backgroundColor: 'lightgrey' }}>
          <Box p={4} pt={4} pb={4} display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mb={2}>
              <Typography variant="h2" style={{ margin: '0' }}>
                {t('settings.titleNewsBanner')}
              </Typography>
            </Box>

            <Typography variant="h6">{t('settings.form.color')}</Typography>

            <FormControl>
              <Select
                labelId="colorFieldLabel"
                id="colorField"
                defaultValue={Colors['Orange']}
                label="Farbe"
                onChange={handleChangeColor}
              >
                {Object.entries(Colors).map(([key, value]) => {
                  return (
                    <MenuItem key={key} value={value}>
                      <span style={{ width: 24, height: 24, backgroundColor: value, marginRight: '.5rem' }} />
                      {key}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Grid item xs style={{ margin: '2rem 2rem 0 0' }}>
              <Typography variant="h6">{t('settings.languages.de')}</Typography>
              <TextField
                id="textFieldDE"
                placeholder={t('settings.form.textPlaceholderDe')}
                value={textDE}
                onChange={(event) => setTextDE(event.target.value)}
                fullWidth={true}
                multiline
                helperText={t('settings.form.helpText')}
              />
            </Grid>

            <Grid item xs style={{ margin: '2rem 2rem 0 0' }}>
              <Typography variant="h6">{t('settings.languages.en')}</Typography>
              <TextField
                id="textFieldEN"
                placeholder={t('settings.form.textPlaceholderEn')}
                value={textEN}
                fullWidth={true}
                onChange={(event) => setTextEN(event.target.value)}
                multiline
                helperText={t('settings.form.helpText')}
              />
            </Grid>

            <Grid item xs style={{ margin: '2rem 2rem 0 0' }}>
              <Typography variant="h6">{t('settings.form.link')}</Typography>
              <TextField
                error={!isValidLink}
                id="linkFieldDE"
                placeholder={t('settings.form.linkPlaceholder')}
                fullWidth={true}
                helperText={t('settings.form.helpTextLink')}
                onChange={(event) => {
                  handleCheckLink(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs style={{ margin: '2rem 0 0 1rem' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleChangeEnabled}
                    size="medium"
                    name="enabled"
                    color="primary"
                    value={!enabled}
                  />
                }
                label={t('settings.form.enabled')}
              />
            </Grid>
            <Grid item xs style={{ margin: '2rem 2rem 0 0' }}>
              <Box display="flex" justifyContent="left">
                <Button
                  disabled={!textDE || !textEN}
                  variant="contained"
                  color="primary"
                  sx={{ borderRadius: '25px', width: '250px', height: '50px' }}
                  component="button"
                  onClick={handleSubmit}
                >
                  {t('settings.save')}
                </Button>
              </Box>
            </Grid>
          </Box>
        </Paper>
      )}
    </Grid>
  );
};

export default Banner;
