import { useQuery } from '@tanstack/react-query';
import { getApiService } from '../api/api-request';

const assetsKeys = {
  all: (lang: string) => ['assets', lang],
  postPresignedUrl: (key: string, lang: string, method: string) => ['asset', key, lang, method],
};

export const useAssetsQuery = (getIdToken: () => Promise<string>, lang: string) =>
  useQuery({
    queryKey: assetsKeys.all(lang),
    queryFn: () => getApiService(getIdToken).getAssets(lang),
  });

export const usePostPresignedUrl = (
  getIdToken: () => Promise<string>,
  key: string,
  lang: string,
  method: 'GET' | 'PUT'
) =>
  useQuery({
    queryKey: assetsKeys.postPresignedUrl(key, lang, method),
    queryFn: async () => await getApiService(getIdToken).postPresignedUrl(key, lang, method),
    enabled: false,
  });
