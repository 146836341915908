import { useEffect, useState } from 'react';
import { getApiService } from '../api/api-request';
import { IAvvEntry } from '../../../sharedTypes/index';

export const useAvvAgreements = (getIdToken: () => Promise<string>) => {
  const [avvAgreements, setAvvAgreements] = useState<IAvvEntry[]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isFailed, setIsFailed] = useState(false);
  const { getAvvAgreements } = getApiService(getIdToken);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(() => true);
        setIsFailed(() => false);
        setAvvAgreements(await getAvvAgreements());
      } catch (err) {
        setIsFailed(() => true);
      } finally {
        setIsLoading(() => false);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addAvvAgreement = (newEntry: IAvvEntry) => {
    setAvvAgreements((old) => (old ? [...old, newEntry] : [newEntry]));
  };

  const deleteAvvAgreement = (toBeDeleted: IAvvEntry) => {
    setAvvAgreements((agreements) =>
      agreements ? agreements.filter((agreement) => agreement.mandantId !== toBeDeleted.mandantId) : []
    );
  };

  return { avvAgreements, isFailed, isLoading, addAvvAgreement, deleteAvvAgreement };
};
