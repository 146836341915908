import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { IAvvEntry } from '../../../../sharedTypes';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { AddCircleOutline, ExpandMore } from '@material-ui/icons';
import MicroFrontendContext from '../MicroFrontendContext';
import { getApiService } from '../../api/api-request';
import { Alert, AlertTitle } from '@material-ui/lab';
import { format } from 'date-fns';

const useStyles = makeStyles((_theme) => ({
  accordionDetails: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '18px',
    padding: '6px',
  },
  fullWidth: {
    width: '100%',
  },
  iconTextBox: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
}));

interface IAddAvvAgreementAccordionProps {
  onSuccess: (newEntry: IAvvEntry) => void;
  agreements: IAvvEntry[];
}
export const AddAvvAgreementAccordion: FunctionComponent<IAddAvvAgreementAccordionProps> = ({
  onSuccess,
  agreements,
}) => {
  const classes = useStyles();
  const { getIdToken } = useContext(MicroFrontendContext);
  const { postAvvAgreement } = getApiService(getIdToken);

  const [newEntry, setNewEntry] = useState<IAvvEntry>({
    mandantId: '',
    source: 'LU-Cockpit',
    timestamp: Date.now(),
  });
  const [submitHasFailed, setSubmitHasFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMandantInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewEntry((entry) => ({ ...entry, mandantId: event.target.value }));
  };

  const handleTimeStampChange = (event: ChangeEvent<HTMLInputElement>) => {
    const ts = new Date(event.target.value);
    if (ts) {
      setNewEntry((entry) => ({ ...entry, timestamp: ts.valueOf() }));
    }
  };

  const mandantIdExists = agreements.findIndex((item) => item.mandantId === newEntry.mandantId) !== -1;

  const handleClickSubmit = async () => {
    try {
      setIsLoading(() => true);
      setSubmitHasFailed(() => false);
      const entryToBeSubmitted: IAvvEntry = {
        ...newEntry,
        timestamp: Date.now(),
      };
      await postAvvAgreement(entryToBeSubmitted);
      onSuccess(entryToBeSubmitted);
      setNewEntry({
        mandantId: '',
        source: 'LU-Cockpit',
        timestamp: 0,
      });
    } catch (error) {
      setSubmitHasFailed(true);
    } finally {
      setIsLoading(() => false);
    }
  };

  return (
    <Accordion className={classes.fullWidth}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Box className={classes.iconTextBox}>
          <AddCircleOutline />
          <Typography variant="subtitle1">
            <Trans i18nKey={'avvTable.add.title'} />
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box className={classes.accordionDetails}>
          <TextField
            variant={'outlined'}
            error={mandantIdExists}
            onChange={handleMandantInputChange}
            label={<Trans i18nKey={'avvTable.header.mandant'} />}
            helperText={mandantIdExists ? <Trans i18nKey={'avvTable.add.mandantExists'} /> : undefined}
            value={newEntry.mandantId}
            className={classes.fullWidth}
          />
          <TextField
            variant={'outlined'}
            type={'datetime-local'}
            onChange={handleTimeStampChange}
            className={classes.fullWidth}
            label={<Trans i18nKey={'avvTable.header.date'} />}
            value={`${format(newEntry.timestamp || Date.now(), 'yyyy-MM-dd')}T${format(
              newEntry.timestamp || Date.now(),
              'HH:mm'
            )}`}
          />
          {isLoading && <LinearProgress className={classes.fullWidth} />}
          {submitHasFailed && (
            <Alert severity={'error'} className={classes.fullWidth}>
              <AlertTitle>
                <Trans i18nKey={'avvTable.add.failed'} />
              </AlertTitle>
            </Alert>
          )}
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button
          disabled={mandantIdExists || !newEntry.mandantId}
          onClick={handleClickSubmit}
          variant={'contained'}
          color={'primary'}
        >
          <Trans i18nKey={'avvTable.add.submit'} />
        </Button>
      </AccordionActions>
    </Accordion>
  );
};
