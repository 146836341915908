import { FunctionComponent, useContext } from 'react';
import { AvvAgreementTable } from './avv-agreement-table';
import { useAvvAgreements } from '../../hooks/use-avv-agreements';
import MicroFrontendContext from '../MicroFrontendContext';
import { Trans } from 'react-i18next';
import { AddAvvAgreementAccordion } from './add-avv-agreement-accordion';
import { Box, CircularProgress } from '@mui/material';

export const AvvAgreementComponent: FunctionComponent = () => {
  const { getIdToken } = useContext(MicroFrontendContext);
  const { avvAgreements, isLoading, isFailed, deleteAvvAgreement, addAvvAgreement } = useAvvAgreements(getIdToken);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      {!isLoading ? (
        avvAgreements && !isFailed ? (
          <>
            <AddAvvAgreementAccordion agreements={avvAgreements} onSuccess={addAvvAgreement} />
            <AvvAgreementTable agreements={avvAgreements} deleteAgreement={deleteAvvAgreement} />
          </>
        ) : (
          <p>
            <Trans i18nKey={'avvTable.errors.fetch'} />
          </p>
        )
      ) : (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', padding: '48px' }}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};
