import { FunctionComponent, ReactNode, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import MicroFrontendContext from '../MicroFrontendContext';
import { usePriceListProductsQuery } from '../../queries/price-list-queries';
import { EditPriceList } from './edit-price-list';
import { AddNewPriceTable } from './add-new-price-list';
import { DeletePriceList } from './delete-price-list';
import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';

export const PriceListManagement: FunctionComponent = () => {
  const { getIdToken } = useContext(MicroFrontendContext);
  const {
    data: products,
    isLoading: productsLoading,
    isError: productsQueryFailed,
  } = usePriceListProductsQuery(getIdToken);
  const [selectedProduct, setSelectedProduct] = useState('');

  const [newProducts, setNewProducts] = useState<string[]>([]);

  const availableProducts = Array.from(new Set([...products, ...newProducts])).sort((a, b) => a.localeCompare(b));

  const handleOnNewProduct = (product: string) => {
    setSelectedProduct(product);
    setNewProducts((old) => [...old, product]);
  };

  const priceListSelectChange = (event: SelectChangeEvent<string>, _child: ReactNode) => {
    setSelectedProduct(event.target.value as string);
  };

  return (
    <Box sx={{ padding: '24px' }}>
      <Typography variant={'h2'}>
        <Trans i18nKey={'priceLists.header'} />
      </Typography>
      {!productsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
          <FormControl sx={{ minWidth: '400px' }} variant={'outlined'}>
            <InputLabel id={'price-list-select-label'}>
              <Trans i18nKey={'priceLists.selectLabel'} />
            </InputLabel>
            <Select
              labelId={'price-list-select-label'}
              onChange={priceListSelectChange}
              value={selectedProduct}
              label={<Trans i18nKey={'priceLists.selectLabel'} />}
            >
              {availableProducts.map((product) => (
                <MenuItem value={product} key={`select-price-list-${product}`}>
                  {product}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <AddNewPriceTable getIdToken={getIdToken} onNewProduct={handleOnNewProduct} />
        </Box>
      ) : (
        <CircularProgress />
      )}
      {!!selectedProduct && (
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '24px', paddingTop: '12px' }}>
          <EditPriceList getIdToken={getIdToken} product={selectedProduct} />
          {products.includes(selectedProduct) && <DeletePriceList getIdToken={getIdToken} product={selectedProduct} />}
          <Alert severity={'info'}>
            <Trans i18nKey={'priceLists.note'} />
          </Alert>
        </Box>
      )}
      {!selectedProduct && (
        <Alert severity={'warning'} sx={{ marginTop: '12px' }}>
          <Trans i18nKey={'priceLists.noPriceListSelected'} />
        </Alert>
      )}
      {productsQueryFailed && (
        <Alert severity={'error'} sx={{ marginTop: '12px' }}>
          <Trans i18nKey={'priceLists.fetchFailed'} />
        </Alert>
      )}
    </Box>
  );
};
