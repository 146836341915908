import { Permission } from '@hmg-aka-lu/cockpit-microfrontend';
import { createContext } from 'react';

export interface IMicroFrontendContextProps {
  getIdToken: () => Promise<string>;
  permissions: Permission[];
}

const MicroFrontendContext = createContext<IMicroFrontendContextProps>({
  getIdToken: async () => '',
  permissions: [],
});

export default MicroFrontendContext;
