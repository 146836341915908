import { FunctionComponent, useState, MouseEvent, useContext } from 'react';
import { Trans } from 'react-i18next';
import { AlertContext } from '../alert-snackbar/alert-context';
import { useDeletePriceListMutation } from '../../queries/price-list-queries';
import { useQueryClient } from '@tanstack/react-query';
import { Accordion, AccordionActions, AccordionSummary, Box, Button, Menu } from '@mui/material';
import { Delete, ExpandMore } from '@mui/icons-material';

interface IDeletePriceListProps {
  getIdToken: () => Promise<string>;
  product: string;
}
export const DeletePriceList: FunctionComponent<IDeletePriceListProps> = ({ getIdToken, product }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const alertContext = useContext(AlertContext);
  const queryClient = useQueryClient();

  const { mutate: deletePriceList, isLoading: isDeleting } = useDeletePriceListMutation(
    getIdToken,
    queryClient,
    alertContext
  );

  const handleDeletePriceList = () => {
    try {
      deletePriceList({ product });
    } catch (error) {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'priceLists.deleteFailed',
      });
    }
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>
            <Delete color={'error'} />
            <Trans i18nKey={'priceLists.deleteHeader'} values={{ product }} />
          </Box>
        </AccordionSummary>
        <AccordionActions>
          <Button variant={'contained'} color={'secondary'} onClick={handleClick}>
            <Trans i18nKey={'common.delete'} />
          </Button>
        </AccordionActions>
      </Accordion>
      <Menu id="delete-priceList-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '12px',
            rowGap: '12px',
            alignItems: 'flex-end',
            maxWidth: '400px',
            textAlign: 'justify',
          }}
        >
          <Trans i18nKey={'priceLists.deleteText'} values={{ product }} />
          <Button variant={'contained'} color={'secondary'} onClick={handleDeletePriceList} disabled={isDeleting}>
            <Trans i18nKey={'common.delete'} />
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};
