import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import { formatISO9075 } from 'date-fns';
import { IAvvEntry } from '../../../../sharedTypes';
import MicroFrontendContext from '../MicroFrontendContext';
import { getApiService } from '../../api/api-request';
import { usePagination } from '../../hooks/use-pagination';
import { DeleteConfirmationDialog } from '../common/delete-confirmation-dialog';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { Delete } from '@mui/icons-material';

const descendingComparator = (a: IAvvEntry, b: IAvvEntry, orderBy: keyof IAvvEntry) => {
  if ((b[orderBy] || 0) < (a[orderBy] || 0)) {
    return -1;
  }
  if ((b[orderBy] || 0) > (a[orderBy] || 0)) {
    return 1;
  }
  return 0;
};

type Order = 'asc' | 'desc';

const getComparator = (order: Order, orderBy: keyof IAvvEntry) => {
  return order === 'desc'
    ? (a: IAvvEntry, b: IAvvEntry) => descendingComparator(a, b, orderBy)
    : (a: IAvvEntry, b: IAvvEntry) => descendingComparator(a, b, orderBy) * -1;
};

interface IVAvvAgreementTableProps {
  agreements: IAvvEntry[];
  deleteAgreement: (agreement: IAvvEntry) => void;
}
export const AvvAgreementTable: FunctionComponent<IVAvvAgreementTableProps> = ({ agreements, deleteAgreement }) => {
  const { getIdToken } = useContext(MicroFrontendContext);
  const { deleteAvvAgreement } = getApiService(getIdToken);
  const [entryToBeDeleted, setEntryToBeDeleted] = useState<IAvvEntry>();
  const [searchText, setSearchText] = useState('');
  const filteredAgreements = searchText
    ? agreements.filter((item) => item.mandantId.toLowerCase().includes(searchText.toLowerCase()))
    : agreements;
  const rowsPerPageOptions = [5, 10, 25, 50, 100];
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[2]);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof IAvvEntry>('timestamp');
  const pagination = usePagination(filteredAgreements.sort(getComparator(order, orderBy)), rowsPerPage);

  const handleDelete = async () => {
    if (entryToBeDeleted) {
      await deleteAvvAgreement(entryToBeDeleted.mandantId);
      deleteAgreement(entryToBeDeleted);
      setEntryToBeDeleted(() => undefined);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleSortLabelClick = (key: keyof IAvvEntry) => {
    setOrderBy(() => key);
    setOrder((old) => (old === 'desc' ? 'asc' : 'desc'));
  };

  const ConfiguredSortLabel: FunctionComponent<{ children: JSX.Element; sortKey: keyof IAvvEntry }> = ({
    children,
    sortKey,
  }) => {
    return (
      <TableSortLabel
        active={orderBy === sortKey}
        direction={order}
        onClick={() => {
          handleSortLabelClick(sortKey);
        }}
      >
        {children}
      </TableSortLabel>
    );
  };

  return (
    <>
      <Box>
        <TextField
          style={{ width: '100%' }}
          label={<Trans i18nKey={'avvTable.search'} />}
          variant={'outlined'}
          value={searchText}
          onChange={handleInputChange}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <ConfiguredSortLabel sortKey={'mandantId'}>
                  <Trans i18nKey={'avvTable.header.mandant'} />
                </ConfiguredSortLabel>
              </TableCell>
              <TableCell>
                <ConfiguredSortLabel sortKey={'userId'}>
                  <Trans i18nKey={'avvTable.header.user'} />
                </ConfiguredSortLabel>
              </TableCell>
              <TableCell>
                <ConfiguredSortLabel sortKey={'timestamp'}>
                  <Trans i18nKey={'avvTable.header.date'} />
                </ConfiguredSortLabel>
              </TableCell>
              <TableCell>
                <ConfiguredSortLabel sortKey={'source'}>
                  <Trans i18nKey={'avvTable.header.source'} />
                </ConfiguredSortLabel>
              </TableCell>
              <TableCell>
                <ConfiguredSortLabel sortKey={'version'}>
                  <Trans i18nKey={'avvTable.header.version'} />
                </ConfiguredSortLabel>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {pagination.currentData().map((agreement) => (
              <TableRow key={`avv-table-row-${agreement.mandantId}`}>
                <TableCell size={'small'}>{agreement.mandantId}</TableCell>
                <TableCell size={'small'}>{agreement.userId}</TableCell>
                <TableCell size={'small'}>{agreement.timestamp ? formatISO9075(agreement.timestamp) : ''}</TableCell>
                <TableCell size={'small'}>{agreement.source}</TableCell>
                <TableCell size={'small'}>{agreement.version}</TableCell>
                <TableCell size={'small'}>
                  <IconButton
                    color={'secondary'}
                    onClick={() => {
                      setEntryToBeDeleted(agreement);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                count={filteredAgreements.length || 0}
                rowsPerPage={rowsPerPage}
                colSpan={6}
                page={pagination.currentPage - 1}
                onPageChange={(_event, page) => {
                  pagination.jump(page + 1);
                }}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(Number.parseInt(event.target.value, 10));
                  pagination.jump(1);
                }}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
      <DeleteConfirmationDialog
        open={entryToBeDeleted !== undefined}
        handleClose={() => {
          setEntryToBeDeleted(undefined);
        }}
        handleDelete={handleDelete}
        warning={<Trans i18nKey={'avvTable.deleteWarning'} values={{ mandantId: entryToBeDeleted?.mandantId }} />}
      />
    </>
  );
};
