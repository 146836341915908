import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

interface IDeleteConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => Promise<void>;
  warning: JSX.Element;
}
export const DeleteConfirmationDialog: FunctionComponent<IDeleteConfirmationDialogProps> = ({
  open,
  handleClose,
  warning,
  handleDelete,
}) => {
  const [deleteFailed, setDeleteFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickDelete = async () => {
    try {
      setIsLoading(() => true);
      setDeleteFailed(() => false);
      await handleDelete();
      handleClose();
    } catch (err) {
      setDeleteFailed(() => true);
    } finally {
      setIsLoading(() => false);
    }
  };

  useEffect(() => {
    if (!open) {
      setDeleteFailed(() => false);
    }
  }, [open]);

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Trans i18nKey={'deleteConfirmation.title'} />
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minHeight: '50px', gap: '24px' }}
        >
          {warning}
          {deleteFailed && !isLoading && (
            <Alert severity={'error'}>
              <AlertTitle>
                <Trans i18nKey={'deleteConfirmation.failed'} />
              </AlertTitle>
            </Alert>
          )}
          {isLoading && <LinearProgress sx={{ width: '100%' }} />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans i18nKey={'common.cancel'} />
        </Button>
        <Button variant={'contained'} color={'secondary'} onClick={handleClickDelete}>
          <Trans i18nKey={'common.delete'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
