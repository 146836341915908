import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { INotification } from '../../../../sharedTypes';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  Accordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';

const AccordionSummary = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
    alignItems: 'center',
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: '0 20px 30px 20px',
    fontSize: '16px',
    lineHeight: '24px',
  },
}))(MuiAccordionDetails);

interface INotificationItemProps {
  index: number;
  notification: INotification;
  expanded: string | false;
  totalItems: number;
  onEdit: (...args: any) => void;
  onMove: (...args: any) => void;
  onDelete: (...args: any) => void;
  onExpand: (...args: any) => void;
}

const NotificationItem: FunctionComponent<INotificationItemProps> = (props) => {
  const { t } = useTranslation();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    props.onExpand(newExpanded ? panel : false);
  };

  const { active, translations, isNew } = props.notification;
  const index = props.index;

  return (
    <Accordion key={index} expanded={props.expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
      <AccordionSummary aria-controls="panel-content" id="panel-header">
        {props.expanded === `panel${index}` ? <ExpandMoreIcon /> : <NavigateNextIcon />}

        <Typography>
          {translations.de.title}
          {isNew && <span>Neu</span>}
          {!active && <i>{`(${t('deactivated')})`}</i>}
        </Typography>
        <Box sx={{ minWidth: 110, display: 'flex', justifyContent: 'end' }}>
          {props.totalItems > 1 && (
            <>
              <IconButton
                color="primary"
                size="small"
                disabled={index <= 0}
                aria-label="up"
                component="span"
                onClick={(event: any) => props.onMove(event, index, index - 1)}
              >
                <ArrowUpwardIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="primary"
                size="small"
                disabled={index + 1 >= props.totalItems}
                aria-label="down"
                component="span"
                onClick={(event: any) => props.onMove(event, index, index + 1)}
              >
                <ArrowDownwardIcon fontSize="small" />
              </IconButton>
            </>
          )}
          <IconButton
            color="primary"
            size="small"
            aria-label="edit"
            component="span"
            onClick={(event: any) => props.onEdit(event, index, props.notification)}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            size="small"
            aria-label="delete"
            component="span"
            onClick={(event: any) => props.onDelete(event, index)}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <small>EN: {translations.en.title}</small>
        <Grid container>
          <Grid item xs style={{ margin: '0 2rem 0 0' }}>
            <Typography variant="h6">{t('settings.languages.de')}</Typography>
            <span
              className={`accordion-content-${index}`}
              dangerouslySetInnerHTML={{ __html: translations.de.content }}
            />
            {translations.de.cta && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component="button"
                  onClick={() => window.open(translations.de.cta?.url)}
                >
                  {translations.de.cta?.label} <OpenInNewIcon fontSize="small" />
                </Button>
              </Box>
            )}
          </Grid>
          <Divider orientation="vertical" style={{ opacity: 0.25 }} flexItem />
          <Grid item xs style={{ margin: '0 0 0 2rem' }}>
            <Typography variant="h6">{t('settings.languages.en')}</Typography>
            <span
              className={`accordion-content-${index}`}
              dangerouslySetInnerHTML={{ __html: translations.en.content }}
            />
            {translations.en.cta && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  component="button"
                  onClick={() => window.open(translations.en.cta?.url)}
                >
                  {translations.en.cta?.label} <OpenInNewIcon fontSize="small" />
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default NotificationItem;
