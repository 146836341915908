import { ChangeEvent, FunctionComponent, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IAsset } from '../../../../sharedTypes';
import { usePostPresignedUrl } from '../../queries/assets-query';
import { AlertContext } from '../alert-snackbar/alert-context';
import { openLinkInNewTab } from '../common/open-link-in-new-tab';
import { Backdrop, Button, Card, CircularProgress, Input, Link, Typography } from '@mui/material';
import { PictureAsPdf, Publish } from '@mui/icons-material';

interface IFileInput {
  file: File;
  path: string;
}

interface IFileUploadItemProps {
  asset: IAsset;
  language: string;
  onSuccess: () => void;
  onError: () => void;
  getIdToken: () => Promise<string>;
}

export const FileUploadItem: FunctionComponent<IFileUploadItemProps> = ({
  asset,
  language,
  onSuccess,
  onError,
  getIdToken,
}) => {
  const [file, setFile] = useState<IFileInput>();
  const { t } = useTranslation();
  const { refetch: refetchGetAssetUrl, isFetching: isFetchingGetUrl } = usePostPresignedUrl(
    getIdToken,
    asset.key,
    language,
    'GET'
  );
  const { refetch: refetchPutUrl } = usePostPresignedUrl(getIdToken, asset.key, language, 'PUT');
  const [isUploading, setIsUploading] = useState(false);
  const alertContext = useContext(AlertContext);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const file = event.currentTarget.files[0];
      if (file.type === asset.MIMEType) {
        setFile({
          file,
          path: event.currentTarget.value || '',
        });
      } else {
        alert(t('settings.assets.invalidFileType'));
      }
    } else {
      setFile(undefined);
    }
  };

  const handleUploadClick = async () => {
    try {
      setIsUploading(true);
      if (file) {
        const url = (await refetchPutUrl()).data;
        if (!url) {
          throw new Error('No Asset-Put-Url');
        }
        const fileUpload = await uploadFile(file.file, url);
        if (fileUpload === 'success') {
          setFile(undefined);
          onSuccess();
        } else {
          throw new Error('File-Upload failed');
        }
      }
    } catch (error) {
      onError();
    } finally {
      setIsUploading(false);
    }
  };

  const handleClickOpenFile = async () => {
    const query = await refetchGetAssetUrl();
    if (query.data) {
      openLinkInNewTab(query.data);
    } else {
      alertContext.enqueueAlert({
        type: 'error',
        i18nKey: 'TODO',
      });
    }
  };

  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', gap: '24px', padding: '16px' }}>
      <Typography variant="h6">
        <Trans i18nKey={`settings.assets.${language}.${asset.key}`} />
      </Typography>
      {
        <Link
          onClick={handleClickOpenFile}
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '12px', cursor: 'pointer' }}
        >
          <PictureAsPdf fontSize={'large'} color={'secondary'} />
          <Typography>{asset.filename}</Typography>
        </Link>
      }
      <Input
        type={'file'}
        onChange={handleInputChange}
        value={file ? file.path : ''}
        inputProps={{ accept: 'application/pdf' }}
      />
      <Button
        variant={'contained'}
        startIcon={<Publish />}
        disabled={!file || isUploading}
        color={'primary'}
        onClick={handleUploadClick}
      >
        <Trans i18nKey={'settings.assets.uploadFile'} />
      </Button>
      <Backdrop
        open={isFetchingGetUrl || isUploading}
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
      >
        <CircularProgress color={'inherit'} />
      </Backdrop>
    </Card>
  );
};

const uploadFile = async (file: File, url: string) => {
  const res = await fetch(url, {
    method: 'PUT',
    body: file,
  });
  return res.ok ? 'success' : 'failed';
};
