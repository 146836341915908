import { FunctionComponent, useContext, useState } from 'react';
import { LwmPage } from '../components/lwm-page';
import { useTranslation } from 'react-i18next';
import ColorPalette from '../theme/ColorPalette';
import { TabPanel } from '../components/TabPanel';
import NewsBanner from '../components/settings/banner';
import MicroFrontendContext from '../components/MicroFrontendContext';
import Notifications from '../components/settings/notifications';
import { AssetManagement } from '../components/settings/assetManagement';
import { AppBar, Box, Tab, Tabs } from '@mui/material';

export const SettingsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const [value, setValue] = useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const { getIdToken } = useContext(MicroFrontendContext);

  return (
    <LwmPage title={t('pages.settings')}>
      <Box
        sx={{
          flexGrow: 1,
          width: '100%',
          backgroundColor: ColorPalette.monochrome.grey10,
          '& .MuiTab-root': {
            paddingTop: 1.5,
            paddingBottom: 1.5,
          },
        }}
      >
        <AppBar position="static" color="default" elevation={1}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={t('settings.tabs.banner')}
              id="scrollable-auto-tab-0"
              aria-controls="scrollable-auto-tabpanel-0"
            />
            <Tab
              label={t('settings.tabs.accordion')}
              id="scrollable-auto-tab-1"
              aria-controls="scrollable-auto-tabpanel-1"
            />
            <Tab
              label={t('settings.tabs.assets')}
              id="scrollable-auto-tab-2"
              aria-controls="scrollable-auto-tabpanel-2"
            />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <NewsBanner getIdToken={getIdToken} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Notifications getIdToken={getIdToken} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <AssetManagement getIdToken={getIdToken} />
        </TabPanel>
      </Box>
    </LwmPage>
  );
};
