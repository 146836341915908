import {
  IAsset,
  IAvvEntry,
  IMandant,
  IMandantenResponse,
  IPriceList,
  IPriceListEntry,
  ISyncMandantenResponse,
  SsoType,
} from '../../../sharedTypes/index';
import mergedConfig from '../config';

interface IUpdateMandantParams {
  id: string;
  scormDownloadEnabled: boolean;
  ssoType: SsoType | null;
}

export const getApiService = (getIdToken: () => Promise<string>) => {
  const apiRequest = async (
    path: string,
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    body?: BodyInit,
    params?: Record<string, string>
  ) => {
    const result = await fetch(`${mergedConfig.API_URL}/${path}?${new URLSearchParams(params)}`, {
      method,
      body,
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${await getIdToken()}`,
      },
    });

    if (result.status >= 400) {
      const data = await result.json();
      throw new Error(data.message);
    }

    return await result.text();
  };

  const getAvvAgreements = async (): Promise<IAvvEntry[]> => {
    const result = await apiRequest('avv-agreement', 'GET');

    return result ? (JSON.parse(result) as IAvvEntry[]) : [];
  };

  const deleteAvvAgreement = async (mandantId: string) => {
    await apiRequest(`avv-agreement/${mandantId}`, 'DELETE');
  };

  const postAvvAgreement = async (entry: IAvvEntry) => {
    await apiRequest('avv-agreement', 'POST', JSON.stringify(entry));
  };

  const getMandanten = async ({ pageParam = undefined }): Promise<IMandantenResponse> => {
    const queryParams = pageParam ? { after: pageParam } : undefined;
    const result = await apiRequest('mandanten', 'GET', undefined, queryParams);

    return JSON.parse(result) as IMandantenResponse;
  };

  const updateMandant = async (params: IUpdateMandantParams): Promise<IMandant> => {
    const result = await apiRequest(
      `mandanten/${params.id}`,
      'PATCH',
      JSON.stringify({
        scormDownloadEnabled: params.scormDownloadEnabled,
        ssoType: params.ssoType,
      })
    );

    return JSON.parse(result) as IMandant;
  };

  const syncMandanten = async () => {
    const result = await apiRequest('mandanten/sync', 'POST');

    return JSON.parse(result) as ISyncMandantenResponse;
  };
  const updateSettings = async (id: string, entry: Record<string, any>) => {
    await apiRequest(`settings/${id}`, 'PUT', JSON.stringify(entry));
  };

  const getSettings = async (id: string): Promise<any> => {
    const result = await apiRequest(`settings/${id}`, 'GET');

    return JSON.parse(result);
  };

  const getFileUploadUrl = async (file: string): Promise<string> => {
    const result = await apiRequest(`file-upload-url/${file}`, 'GET');

    return JSON.parse(result);
  };

  const putPriceListEntry = async (entry: IPriceListEntry) => {
    await apiRequest('price-list', 'PUT', JSON.stringify(entry));
  };

  const deletePriceListEntry = async (product: string, pieces: number) => {
    await apiRequest(`price-list/${encodeURIComponent(product)}/${encodeURIComponent(pieces)}`, 'DELETE');
  };

  const getProducts = async (): Promise<string[]> => {
    const result = await apiRequest('price-list-product', 'GET');

    return JSON.parse(result);
  };

  const getPriceList = async (product: string): Promise<IPriceList> => {
    const result = await apiRequest(`price-list/${encodeURIComponent(product)}`, 'GET');

    return JSON.parse(result);
  };

  const deletePriceList = async (product: string) => {
    await apiRequest(`price-list/${encodeURIComponent(product)}`, 'DELETE');
  };

  const getAssets = async (lang: string): Promise<IAsset[]> => {
    const result = await apiRequest(`assets/${encodeURIComponent(lang)}`, 'GET');

    return JSON.parse(result);
  };

  const postPresignedUrl = async (key: string, lang: string, method: 'GET' | 'PUT'): Promise<string> => {
    const result = await apiRequest(
      `presigned-url/${encodeURIComponent(key)}/${encodeURIComponent(lang)}`,
      'POST',
      JSON.stringify({
        method,
      })
    );
    return JSON.parse(result);
  };

  return {
    getAvvAgreements,
    deleteAvvAgreement,
    postAvvAgreement,
    getMandanten,
    updateMandant,
    syncMandanten,
    updateSettings,
    getSettings,
    getFileUploadUrl,
    getPriceList,
    putPriceListEntry,
    deletePriceListEntry,
    deletePriceList,
    getProducts,
    getAssets,
    postPresignedUrl,
  };
};
